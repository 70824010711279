var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "kt-subheader kt-grid__item",
        attrs: { id: "kt_subheader" },
      },
      [
        _c("div", { staticClass: "kt-container kt-container--fluid" }, [
          _c("div", { staticClass: "kt-subheader__main" }, [
            _c(
              "div",
              {
                staticClass:
                  "kt-input-icon kt-input-icon--right kt-subheader__search mt-1",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchString,
                      expression: "searchString",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Filter..." },
                  domProps: { value: _vm.searchString },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchString = $event.target.value
                    },
                  },
                }),
                _vm._m(0),
              ]
            ),
            _vm.subscriptions.length
              ? _c("h3", { staticClass: "kt-subheader__title ml-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.subscriptions.length) +
                      " active, " +
                      _vm._s(_vm.lockedSubscriptions.length) +
                      " subscribed - " +
                      _vm._s(_vm.thisYear) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "kt-subheader__toolbar" }, [
            _c(
              "div",
              { staticClass: "kt-subheader__wrapper" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-label-success btn-bold",
                    attrs: {
                      to: {
                        name: "CreateOrEditSchool",
                        params: { externalSchoolId: "new" },
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "flaticon2-user" }),
                    _vm._v(" Add School "),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-label-primary btn-bold",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.downloadUserCSV.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "flaticon2-download" }),
                    _vm._v(" Download Users "),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-striped table-hover" }, [
                _vm._m(1),
                _c(
                  "tbody",
                  _vm._l(_vm.schools, function (school, idx) {
                    return _c(
                      "tr",
                      {
                        key: `${idx}_${school.externalSchoolId}`,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.editSchool(school)
                          },
                        },
                      },
                      [
                        !school.deleted
                          ? _c("td", { staticStyle: { width: "280px" } }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-label-primary btn-bold mr-4",
                                  attrs: {
                                    type: "button",
                                    title: "Impersonate",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.impersonate(school)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-user-secret" }),
                                  _vm._v(" Impersonate "),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "school-ext-id-badge",
                                  class: [
                                    `color${Math.floor(
                                      (school.externalSchoolId.charCodeAt(
                                        school.externalSchoolId.length - 1
                                      ) +
                                        idx) %
                                        10
                                    )}`,
                                  ],
                                  attrs: { title: "Edit School" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(school.externalSchoolId) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "school-ext-id-badge",
                                  staticStyle: {
                                    "min-width": "80px",
                                    display: "inline-block",
                                    "strike-through": "true",
                                  },
                                  attrs: { title: "Edit School" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(school.externalSchoolId) + " "
                                  ),
                                ]
                              ),
                            ]),
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "kt-font-bold",
                                class: school.deleted ? "kt-font-danger" : "",
                                style: school.deleted
                                  ? "text-decoration: line-through;"
                                  : "",
                                attrs: {
                                  to: {
                                    name: "CreateOrEditSchool",
                                    params: {
                                      externalSchoolId: school.externalSchoolId,
                                    },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(school.schoolName) + " ")]
                            ),
                            _c("div", { staticClass: "text-muted" }, [
                              _vm._v(" " + _vm._s(school.district.name) + " "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("SchoolSubscriptionLabel", {
                              attrs: {
                                "purchase-order-number":
                                  school.subscriptionLabels
                                    .lastYearPurchaseOrder,
                                deleted: !school.subscriptionLabels.lastYear,
                                "school-year": _vm.lastYear,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("SchoolSubscriptionLabel", {
                              attrs: {
                                "purchase-order-number":
                                  school.subscriptionLabels
                                    .thisYearPurchaseOrder,
                                deleted: !school.subscriptionLabels.thisYear,
                                "school-year": _vm.thisYear,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("SchoolSubscriptionLabel", {
                              attrs: {
                                "purchase-order-number":
                                  school.subscriptionLabels
                                    .nextYearPurchaseOrder,
                                deleted: !school.subscriptionLabels.nextYear,
                                "school-year": _vm.nextYear,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "flaticon2-search-1" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "180px" }, attrs: { colspan: "2" } }, [
          _vm._v(" School "),
        ]),
        _c("th", { attrs: { colspan: "3" } }, [_vm._v(" Subscriptions ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }