var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: _vm.title,
          expression: "title",
          modifiers: { hover: true },
        },
      ],
      staticClass:
        "kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-bolder",
      class: {
        "kt-badge kt-badge--danger": _vm.deleted,
        "kt-badge kt-badge--success": !_vm.deleted && !_vm.purchaseOrderNumber,
        "kt-badge kt-badge--primary": !_vm.deleted && _vm.purchaseOrderNumber,
      },
    },
    [_vm._v(" " + _vm._s(_vm.schoolYear) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }