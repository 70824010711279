<template>
<div>
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="searchString"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>
                <h3
                    v-if="subscriptions.length"
                    class="kt-subheader__title ml-3"
                >
                    {{ subscriptions.length }} active, {{ lockedSubscriptions.length }} subscribed - {{ thisYear }}
                </h3>
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper">
                    <router-link
                        :to="{ name: 'CreateOrEditSchool', params: { externalSchoolId: 'new' } }"
                        class="btn btn-label-success btn-bold"
                    >
                        <i class="flaticon2-user" />
                        Add School
                    </router-link>
                    <a
                        href="#"
                        class="btn btn-label-primary btn-bold"
                        @click.stop.prevent="downloadUserCSV"
                    >
                        <i class="flaticon2-download" />
                        Download Users
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th colspan="2" style="width: 180px">
                                    School
                                </th>
                                <th
                                    colspan="3"
                                >
                                    Subscriptions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(school, idx) in schools"
                                :key="`${idx}_${school.externalSchoolId}`"
                                @click.stop.prevent="editSchool(school)"
                            >
                                <td v-if="!school.deleted" style="width: 280px">
                                    <button
                                        type="button"
                                        class="btn btn-label-primary btn-bold mr-4"
                                        title="Impersonate"
                                        @click.stop.prevent="impersonate(school)"
                                    >
                                        <i class="fa fa-user-secret" />
                                        Impersonate
                                    </button>
                                    <span
                                        class="school-ext-id-badge"
                                        title="Edit School"
                                        :class="[
                                            `color${Math.floor((school.externalSchoolId.charCodeAt(school.externalSchoolId.length - 1) + idx) % 10)}`,
                                        ]"
                                    >
                                        {{ school.externalSchoolId }}
                                    </span>
                                </td>
                                <td v-else>
                                    <span
                                        class="school-ext-id-badge"
                                        title="Edit School"
                                        style="min-width: 80px; display: inline-block; strike-through: true;"
                                    >
                                        {{ school.externalSchoolId }}
                                    </span>
                                </td>
                                <td>
                                    <router-link
                                        :to="{ name: 'CreateOrEditSchool', params: { externalSchoolId: school.externalSchoolId } }"
                                        class="kt-font-bold"
                                        :class="school.deleted ? 'kt-font-danger' : ''"
                                        :style="school.deleted ? 'text-decoration: line-through;' : ''"
                                    >
                                        {{ school.schoolName }}
                                    </router-link>
                                    <div class="text-muted">
                                        {{ school.district.name }}
                                    </div>
                                </td>
                                <td>
                                    <SchoolSubscriptionLabel
                                        :purchase-order-number="school.subscriptionLabels.lastYearPurchaseOrder"
                                        :deleted="!school.subscriptionLabels.lastYear"
                                        :school-year="lastYear"
                                    />
                                </td>
                                <td>
                                    <SchoolSubscriptionLabel
                                        :purchase-order-number="school.subscriptionLabels.thisYearPurchaseOrder"
                                        :deleted="!school.subscriptionLabels.thisYear"
                                        :school-year="thisYear"
                                    />
                                </td>
                                <td>
                                    <SchoolSubscriptionLabel
                                        :purchase-order-number="school.subscriptionLabels.nextYearPurchaseOrder"
                                        :deleted="!school.subscriptionLabels.nextYear"
                                        :school-year="nextYear"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import moment from 'moment';
import SchoolSubscriptionLabel from './SchoolSubscriptionLabel.vue';
import Types from '../../store/Types';

export default {
    name: 'SuperSchools',
    components: { SchoolSubscriptionLabel },
    data() {
        return {
            searchString: '',
        };
    },
    computed: {
        ...mapState({
            schools: (state) => state.database.schools,
            deviceType: (state) => state.deviceType,
        }),
        lockedSubscriptions() {
            const { subscriptions } = this;
            return subscriptions.filter((s) => s.isAdminLocked);
        },
        subscriptions() {
            const { thisYear, schools } = this;
            return schools.filter(function(s) {
                return s.subscriptions.find((x) => x.schoolYear == thisYear);
            });
        },
        lastYear() {
            return moment().add(-1, 'years').format('YYYY');
        },
        thisYear() {
            if (parseInt(moment().format('MM'), 10) < 7) {
                return moment().add(-1, 'years').format('YYYY');
            }
            return moment().format('YYYY');
        },
        nextYear() {
            return moment().add(1, 'years').format('YYYY');
        },
        schools() {
            const searchString = (this.searchString || '').toLowerCase();
            const arraySortOrder = function (a, b) {
                if (a.deleted || b.deleted) return 1;
                if (a.sort < b.sort) {
                    return 1;
                }
                if (a.sort > b.sort) {

                    return -1;
                }
                return 0;
            };

            const { thisYear, nextYear, lastYear } = this;
            const { schools } = this.$store.state.database;
            return schools.map((s) => {
                const school = s;
                school.age = moment(`${s.created}`).fromNow();
                school.sort = `${s.schoolName}`;
                school.search = `${s.externalSchoolId}${s.schoolName}`.toLowerCase();

                school.subscriptionLabels = {
                    lastYear: Boolean(school.subscriptions.find((x) => x.schoolYear == lastYear && !x.deleted)),
                    lastYearPurchaseOrder: this.getPurchaseOrderNumber(school, lastYear),
                    thisYear: Boolean(school.subscriptions.find((x) => x.schoolYear == thisYear && !x.deleted)),
                    thisYearPurchaseOrder: this.getPurchaseOrderNumber(school, thisYear),
                    nextYear: Boolean(school.subscriptions.find((x) => x.schoolYear == nextYear && !x.deleted)),
                    nextYearPurchaseOrder: this.getPurchaseOrderNumber(school, nextYear),
                };

                return school;
            })
                .filter((t) => (searchString ? t.search.indexOf(searchString.toLowerCase()) > -1 : true))
                .sort(arraySortOrder);
        },
    },
    methods: {
        downloadUserCSV() {
            window.location.href = `/api/sg-admin/users/subscriptions/${this.thisYear}`
        },
        impersonate(school) {
            this.$store.dispatch(Types.actions.IMPERSONATE_PRIMARY, school);
        },
        getPurchaseOrderNumber(school, value) {
            const subscription = school.subscriptions.find((x) => x.schoolYear == value && !x.deleted && x.purchaseOrderNumber);
            return subscription ? subscription.purchaseOrderNumber : '';
        },
        editSchool(school) {
            const { externalSchoolId } = school;
            this.$router.push({ name: 'CreateOrEditSchool', params: { externalSchoolId } });
        },
    },
};
</script>

<style scoped>
tbody tr td {
    cursor: pointer;
    vertical-align: middle;
}
</style>
