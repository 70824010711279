<template>
    <span v-b-tooltip.hover="title" class="kt-badge--inline kt-badge--pill kt-badge--rounded kt-font-bolder" :class="{
        'kt-badge kt-badge--danger': deleted,
        'kt-badge kt-badge--success': !deleted && !purchaseOrderNumber,
        'kt-badge kt-badge--primary': !deleted && purchaseOrderNumber,
    }">
        {{ schoolYear }}
    </span>
</template>

<script  lang="ts">

export default {
    name: 'SchoolSubscriptionLabel',
    props: {
        purchaseOrderNumber: {
            type: String,
            required: false,
            default: '',
        },
        deleted: {
            type: Boolean,
            required: true,
        },
        schoolYear: {
            type: String,
            required: true,
        },
    },
    computed: {
        title() {
            if (this.deleted) return `Missing Subscription for ${this.schoolYear}`;
            if (!this.deleted && !this.purchaseOrderNumber) return 'Missing PO #';
            return 'Subscribed and Paid';
        },
    },
};
</script>
